import React from "react"
import { graphql } from "gatsby"
import Layout from "Layout/Layout"
import FlexibleContent from "Layout/FlexibleContent/FlexibleContent"
import { HelmetProvider } from 'react-helmet-async';

const Project = ({ data, location }) => {
  const { project } = data
  // console.log("🚀 ~ file: Project.js ~ line 8 ~ Project ~ project", project)

  return (
    <HelmetProvider>
      <Layout
        metaData={project.acfPageMetaInfos}
        location={location}
        locale={project.language?.locale}
        translations={project.translations}
      >
        {project.flexContent?.flex && (
          <FlexibleContent
            content={project.flexContent.flex}
            location={location}
            locale={project.language?.locale}
            categories={project.projectCategories?.nodes?.map(item => { return item.name })}
            postId={project.id}
          />
        )}
      </Layout>
    </HelmetProvider>
  )
}

export const query = graphql`
  query ProjectQuery($id: String!) {
    project:
      wpProject(id: { eq: $id }) {
        title
        id
        acfPageMetaInfos {
          metaInfoTitle
          metaInfoDescription
          metaInfoKeywords
          metaInfoShareImage {
            sourceUrl
          }
        }
        language {
          locale
        }
        translations {
          uri
          language {
            locale
            slug
          }
        }
        projectCategories {
          nodes {
            name
          }
        }
        flexContent {
          flex {
            ... on WpProject_Flexcontent_Flex_AccordionList {
              fieldGroupName
              text
              items {
                type
                label
                open
                commonLink {
                  url
                  title
                  target
                }
                buttonLink {
                  url
                  title
                  target
                }
                content {
                  text
                }
              }
            }
            ... on WpProject_Flexcontent_Flex_MediaGrid {
              fieldGroupName
              mediaGridType
              maxWidthLayout
              reducedMarginTop
              media {
                caption
                captionOverlap
                scrollytelling
                maxHeight
                controls
                image {
                  ...LocalFile
                }
                reverse
                subcaption
                type
                keepRatio
                video {
                  ...Video
                }
                vimeoembed
                buttonLink {
                  url
                  title
                  target
                }
                commonLink {
                  url
                  title
                  target
                }
                hubSpotEmailForm {
                  portalid
                  formid
                  hasHubspotEmailFormInput
                }
              }
            }
            ... on WpProject_Flexcontent_Flex_MediaGridScrollytelling{
              fieldGroupName
              caption {
                text,
                alignmentType
              }
            }
            ... on WpProject_Flexcontent_Flex_MediaSlider {
              fieldGroupName
              maxWidthLayout
              slidesPerView
              autoPlay
              hideControls
              title
              reducedMarginTop
              wideLayout
              media {
                caption
                type
                image {
                  ...LocalFile
                }
                video {
                  ...Video
                }
              }
            }
            ... on WpProject_Flexcontent_Flex_PostOverview {
              fieldGroupName
              properties {
                filter
                layout
                scope
                type
              }
              curatedPosts {
                project {
                  ... on WpProject {
                    nodeType
                    id
                  }
                }
                page {
                  ... on WpPage {
                    nodeType
                    id
                  }
                }
              }
            }
            ... on WpProject_Flexcontent_Flex_HubSpotForm {
              fieldGroupName
              title
              jumpToId
              portalId
              formId
            }
            ... on WpProject_Flexcontent_Flex_TextGrid {
              fieldGroupName
              textGridType
              reducedMarginTop
              scrollytelling
              texts {
                text
                buttonLink {
                  url
                  title
                  target
                }
                commonLink {
                  url
                  title
                  target
                }
                hubSpotEmailForm {
                  portalid
                  formid
                  hasHubspotEmailFormInput
                }
              }
            }
            ... on WpProject_Flexcontent_Flex_HeroOpenerImage{
              fieldGroupName
              texts {
                text
                buttonLink {
                  url
                  title
                  target
                }
                commonLink {
                  url
                  title
                  target
                }
              }
              backgroundImage {
                id
                sourceUrl
              }
              backgroundColor
            }
            ... on WpProject_Flexcontent_Flex_ComparisonOverview {
              fieldGroupName
              overviews {
                textGridType
                texts {
                  text
                  buttonLink {
                    url
                    title
                    target
                  }
                  commonLink {
                    url
                    title
                    target
                  }
                }
                criterias {
                  key
                  description
                }
                features {
                  name
                  terms {
                    criteriaKey
                    content
                    project {
                      ... on WpProject {
                        nodeType
                        id
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
  }
`

export default Project
